import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const lnglocal = sessionStorage.getItem("lng");

i18n.use(initReactI18next).init({
  resources: {
    ar: {
      translation: {
        English: "الانكليزية",
        Arabic: "العربية",
        Home: "الرئيسية",
        WhoAreWe: "من نحن",
        OurServices: "خدماتنا",
        OurTeam: "المؤسسون",
        ConactUs: "اتصل بنا",
        HomeIntroText:
          "من أجل مواكبة النمو السريع والتطور في دولة العراق و الشرق الاوسط ، تم تأسيس شركتنا وفق أهداف واستراتيجية محددة بما ينعكس على سرعة أداء و فاعلية عملها و بما يلبي أخر مستجدات السوق و المنافسة المتزايدة. قمنا بإعداد و تنشئة كوادر عملنا المتواجدة في العراق و قطر و سوريا لنكون واحد من اللاعبين الأساسيين في سوق الأعمال المحلية، و لنمثل صفة الشريك الاستراتيجي مع الشركات الصغيرة و المتوسطة الحجم من جهة، و مع العملاء الأفراد من جهة أخرى.",
        WhoAreWeBoldText: "نقدم الخدمات لتحسين منظومتك لتتميز بعملك",
        WhoAreWeLightText:
          "تقدم شركتنا لعملائها خدمات التدقيق والمحاسبة والاستشارات التجارية التي تتسم بأعلى مستوى من الفعالية والقيمة المضافة من الدرجة الأولى. ويتألف عملاؤنا في معظمهم من المؤسسات الكبيرة والشركات الصغيرة والمتوسطة التي تقوم بأنشطتها وأعمالها التجارية في جميع أنحاء العالم. وقد تمكنا من الوصول إلى مكانة متميزة بفضل ما نقدمه من خدمات خالية من الأخطاء والعيوب والتي تقدم لعملائنا في الوقت المناسب دعماً يمكن الاعتماد عليه دون أخطاء.",
        WhoAreWeLightText2:
          "وفي إطار جهودنا المستمرة التي نبذلها لنوفر لعملائنا أفضل عروض الخدمة في الوقت المناسب، فقد بذلنا جهدنا دائماً لتوفير عروض خدمة تستند إلى رؤيتنا الثاقبة ومعرفتنا العميقة بهذا المجال مع إهتمام بوجه خاص باحتياجات العملاء. ولا يخفى علينا أن لكل عميل متطلباته الخاصة والفريدة، مما يتوجب علينا دعمهم في كل ما يحتاجونه، وهذا ما دأبنا عليه منذ أول يوم أسسنا فيه هذه الشركة.",
        Vision: "رؤيتنا",
        Values: "قيمنا",
        Goals: "هدفنا",
        VisionText:
          "تتمثل رؤيتنا في أن نصبح أحد أكثر الأسماء المسؤولة الموثوق بها والمفضلة في مجال التدقيق والمحاسبة وخدمات الاستشارات التجارية لدى جميع العملاء في الأسواق المحلية و العالمية بتكلفة يسيرة وجودة لا مثيل لها مع تحقيق نتائج باهرة",
        ValuesText:
          "الصدق والأمانة والالتزام بتقديم خدمات متميزة لعملائنا في القطاعين العام والخاص وبكل حيادية وموضوعية من خلال الالتزام التام بتطبيق المعايير المهنية ذات العلاقة.",
        GoalsText:
          "أما مهمتنا فتتمثل في ضمان تقديم خدمة ممتازة لجميع عملائنا أياً كان مجال تلك الخدمة ونطاق أعمالهم بما يلائم احتياجاتهم من الخدمات الاستشارية تماماً من خلال أنظمة خدمات مدروسة جيداً تحت إشراف متخصصين مؤهلين ومدربين في مجال تقديم خدمات المحاسبة والتدقيق والاستشارات التجارية في الوقت المحدد.",
        ServiceOneTitle: "الاستشارات المحاسبية ومسك الدفاتر",
        ServiceOneText:
          "الاستشارات المحاسبية لها اهمية كبيرة في كيفية انشاء وحفظ السجلات المحاسبية. الاستشارات المحاسبية هي عملية دقيقة تهدف الى توثيق وتسجيل جميع المعلومات المتعلقة بالمعاملات والأنشطة المالية للشركة .",
        ServiceOneDesign: "تصميم الأنظمة ودليل الحسابات",
        ServiceOneMenu: "إعداد القوائم المالية التفصيلية",
        ServiceOneSallery: "خدمات سجل الرواتب",
        ServiceOneTaxes: "الامتثال لنظام الضرائب",
        ServiceOneReport: "تقارير الأداء الشهرية والربعية",
        ServiceOneStorage:
          "خدمات مسك الحسابات بما في ذلك الايردات والمصاريف والتحصيلات والمخازن",
        ServiceTwoTitle: "تطوير السياسات والإجراءات وإدارة المخاطر",
        ServiceTwo1:
          "مخاطر الشركات تشير إلى السلبيات والمخاطر التي تواجهها الشركة، وإدارة هذه المخاطر هي مجموعة من الإجراءات التي تقلل من المخاطر والتكاليف للشركات. إن مهمة إدارة المخاطر المؤسسية عبارة عن تحديد المصادر المحتملة للمشاكل وتحليلها واتخاذ الخطوات اللازمة لمنع الخسائر.",
        ServiceTwo2:
          "هناك عدة خطوات في أي عملية إدارة المخاطر، ويجب على الإدارة تحديد وقياس التعرض للخسارة، واختيار بدائل لتلك الخسارة، وتنفيذ الحل، ورصد نتائج هذا الحل. الهدف من فريق إدارة المخاطر هو حماية وتعزيز قيمة الشركة في نهاية المطاف.",
        ServiceTwo3:
          "يوفر لك فريقنا الحلول المناسبة لدرء المخاطر عن اعمالك من خلال أتخاذ اجراءات استباقية تمكن من تلافي وقوع الشركة في مخاطر مالية وإدارية.",
        ServiceThreeTitle: "خدمات التدقيق والرقابة",
        ServiceThreeText1:
          "التدقيق والرقابة تعتبر من الأدوات الهامة للإدارة للتأكد من حسن سير العمل لتحقيق اهداف المؤسسة",
        ServiceThreeText2:
          " يقدم فريقنا العديد من الخدمات لتقديم تأكيد للإدارة حول حسن سير عمليات الشركة المالية والتشغيلية",
        ServiceThreeInside: "خدمات التدقيق الداخلي.",
        ServiceThreeMoney: "خدمات الرقابة المالية",
        ServiceThreeReport: "التقارير الدورية للإدارة حول الأداء المالي",
        KnowOurTeam: "تعرف على فريقنا",
        ServiceFourTitle: "تطوير التخطيط والتحليل المالي والتقارير الدورية",
        ServiceFourText:
          "يستخدم التحليل المالي لتقييم الاتجاهات الاقتصادية، و وضع السياسة المالية، وبناء خطط طويلة الأجل لسير الأعمال، ويتم ذلك من خلال قراءة الأرقام المالية والبيانات عن طريق:",
        ServiceFourAnnual: "إعداد الموازنة السنوية",
        SerivceFourAnalayze: "تحليل القوائم المالية",
        ServiceFourReport: "التقارير الدورية للإدارة حول الأداء المالي",
        ServiceFourStart: "أبداء الأن معنا",
        ServiceFiveTitle: "الاستشارات المالية ودراسات الجدوى الاقتصادية",
        ServiceFiveText1:
          "نحن نقدم لأصحاب المشاريع نصائح مالية ونقدية ونساعد في إعداد دراسة الجدوى الاقتصادية على أساس دراسات السوق.",
        ServiceFiveText2:
          "خبراتنا المتراكمة في إعداد دراسات الجدوى جعلت لنا مكانة بارزة في هذا المجال حيث إن إعداد دراسات الجدوى الاقتصادية للمشاريع الاقتصادية يشمل الأقسام التالية:",
        ServiceFiveMoney: "الاستشارات الماليه والنقديه",
        ServiceFiveEconomy: "دراسه الجدوي الاقتصاديه",
        ServiceSixTitle: "استشارات البرمجية المحاسبية",
        ServiceSixText1:
          "يستهلك استخراج التقارير المالية وقت طويل جدا منك ؟ ليس لديك قوالب مخصصة لفواتير بنفس هوية مؤسستك وشركتك لارسالها لعملائك بالوقت المطلوب حال استحقاق دفعات لك ؟ والكثير من الصعوبات والمعاناة التي يعانيها اصحاب الشركات الصغيرة والمتوسطة ورواد الاعمال لعدم امتلاكهم البرامج الاساسية التي تسهل سير حياة مشاريعهم .",
        ServiceSixText2:
          "شركتنا تستطيع أن تؤمن لك كل ذلك وتذلل لك الصعوبات , بحيث تجعل أمورك المالية مرتبة ومنسقة ونظامية من دون أي اخطاء , بحيث تصل الى رؤية مالية واضحة من خلال:",
        ServiceSixHelp: "المساعدة في اختيار البرامج المحاسبية",
        ServiceSixImplement: "تنفيذ البرامج المحاسبية في المؤسسات",
        ServiceSixFollow: "المتابعة المالية مابعد تنفيذ البرامج المحاسبية",
        ServiceSevenTitle: "خدمات تدريب كوادر الشركات",
        ServiceSevenText:
          "نحن نقدم دورات تدريبية لعملاءنا في العديد من المجالات مثل:",
        ServiceSeven1: "خدمات التدريب الإداري",
        ServiceSeven2:
          "خدمات التدريب المالي كإعداد الموازنات السنوية واعداد القوائم المالية ومسك الدفاتر",
        ServiceSeven3: "خدمات التدريب على اعداد التقارير الدورية.",
        ServiceSeven4: "خدمات التدريب على معايير المحاسبة الدولية.",
        ServiceSeven5:
          "خدمات تدريب الشهادات المهنية مثل CMA, CIA, CPA, DipIFRS.",
        OurTeamText:
          "يتميز فريق عملنا المتواجد في العراق و سوريا و قطر بخبرات تزيد عن عشرة سنين في مجالات المحاسبة و التدقيق و الحاصل على العديد من الشهادات و التي تساعد في تقديم افضل CPA, CMA, MSC, DipIFRS المهنية خدمات للعملاء و بأسعار تنافسية.",
        FooterText:
          "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص",
        OurPages: "الصفحات",
        OurClients: "عملائنا",
        OurClientsText: "عملاء نتشرف بتقديم خدماتنا لهم",
        CientPageFirstTitle: "فريق ستيتمنتس المتميز",
        ClientPageSecondTitle:
          "يقوم بخدمة العديد من العملاء في الشرق الأوسط وأوروبا و الولايات المتحدة الأمريكية.",
        ClientPageText:
          "لكل صناعة إحتياجاتها و تطبيقاتها التى تميزها عن غيرها و بفضل الخبرة المتوفرة لدى فريقنا تقدم شركتنا خدماتها لقطاعات الأعمال كافة لتغطي جميع المجالات على سبيل المثال لا الحصر:",
        ClientPageCat1: "الشركات الإستثمارية.",
        ClientPageCat2: "الشركات المساهمة.",
        ClientPageCat3: "المصانع والأنشطة الصناعية.",
        ClientPageCat4: "شركات المقاولات.",
        ClientPageCat5: "المؤسسات المالية والتأمين.",
        ClientPageCat6: "الأندية الرياضية والإجتماعية.",
        ClientPageCat7: "المحلات التجارية.",
      },
    },
    en: {
      translation: {
        English: "English",
        Arabic: "Arabic",
        Home: "Home",
        WhoAreWe: "Who Are We",
        OurServices: "Our Services",
        OurTeam: "FOUNDERS",
        ConactUs: "Contact Us",
        HomeIntroText:
          "In order to keep pace with the rapid growth and development in the State of Iraq and the Middle East, our company was established according to specific objectives and strategy, which reflects on the speed of performance and effectiveness of its work, and to meet the latest developments in the market and the increasing competition. We have prepared and cultivated our staffs located in Iraq, Qatar and Syria to be one of the main players in the local business market, and to represent the status of a strategic partner with small and medium-sized companies on the one hand, and with individual customers on the other hand.",
        WhoAreWeBoldText:
          "We Provide You With Our Services To Enhance Your Systems & Make Your Business Distinctive",
        WhoAreWeLightText:
          "Our company provides its clients with auditing, accounting and business consulting services that are characterized by the highest level of effectiveness and first-class added value. Our clients are mostly large institutions and small and medium companies that carry out their activities and businesses all over the world. We have been able to reach a distinguished position due to the services we provide that are free from errors and defects, and that provides our customers with timely and reliable support without errors",
        WhoAreWeLightText2:
          "In our continuous efforts to provide our customers with the best service offers at the right time, we have always made every effort to provide service offers based on our insight and deep knowledge of this field with special attention to customer needs. It is obvious that each customer has his own and unique requirements, which requires us to support them in everything they need, and this is what we have been doing since the first day we established this company.",
        Vision: " Visions",
        Values: " Values",
        Goals: " Goal",
        VisionText:
          "Our vision is to become one of the most reliable and preferred responsible names in the field of auditing, accounting and business advisory services to all clients in the local and global markets at an affordable cost and unparalleled quality with impressive results.",
        ValuesText:
          "Honesty, honesty and commitment to provide distinguished services to our clients in the public and private sectors, with all impartiality and objectivity, through full commitment to the application of relevant professional standards.",
        GoalsText:
          "As for our mission, it is to ensure the provision of excellent service to all our clients, whatever the field of that service and the scope of their business, in a way that fully suits their needs of advisor y services through well-studied service systems under the super vision of qualified and trained specialists in the field of providing accounting, auditing and business consulting ser vices on time.",
        ServiceOneTitle: "ACCOUNTING CONSULTING AND BOOKKEEPING",
        ServiceOneText:
          "Accounting consultancy is of great importance in how to create and maintain accounting records. Accounting consultancy is a precise process aimed at documenting and recording all information related to the company's financial transactions and activities.",
        ServiceOneDesign:
          "Establishing Full Accounting System and Chart of Accounts.",
        ServiceOneMenu: "Preparation of detailed financial statements.",
        ServiceOneSallery: "Payroll services.",
        ServiceOneTaxes: "Compliance with the tax system.",
        ServiceOneReport: "Monthly and quarterly performance reports.",
        ServiceOneStorage:
          "Bookkeeping services, including revenues, expenses, collections, and stores.",
        ServiceTwoTitle:
          "RISK MANAGEMENT AND DEVELOPING POLICIES AND PROCEDURES",
        ServiceTwo1:
          "Corporate risk refers to the negatives and risks faced by the company, and the management of these risks is a set of procedures that reduce risks and costs for companies. The mission of ERM is to identify potential sources of problems, analyze them and take steps to prevent losses.",
        ServiceTwo2:
          "There are several steps in any risk management process, and management must identify and measure exposure to loss, choose alternatives to that loss, implement a solution, and monitor the results of that solution. The goal of the risk management team is to ultimately protect and enhance the value of the company.",
        ServiceTwo3:
          "Our team provides you with appropriate solutions to ward off risks for your business by taking proactive Procedures (Actions) that enable the company to avoid financial and administrative risks",
        ServiceThreeTitle: "AUDIT AND CONTROL SERVICES",
        ServiceThreeText1:
          "Auditing and control are among the important tools of management to ensure the proper functioning of work to achieve the objectives of the institution.",
        ServiceThreeText2:
          "Our team provides several services to provide assurance to management about the smooth running of the company's financial and operational processes",
        ServiceThreeInside: "Internal Audit Services.",
        ServiceThreeMoney: "Financial Audit services.",
        ServiceThreeReport:
          "Periodic management reports on financial performance.",
        KnowOurTeam: "Get to know our team",
        ServiceFourTitle:
          "DEVELOPING PLANNING, FINANCIAL ANALYSIS AND PERIODIC REPORTS",
        ServiceFourText:
          "Financial analysis is used to assess economic trends, set financial policy, build long-term plans for business activity, and identify projects or companies for investment. This is done by synthesizing financial numbers and data.",
        ServiceFourAnnual: "Preparing the annual budget.",
        SerivceFourAnalayze: "Financial Statements analysis.",
        ServiceFourReport: "Periodic reports on financial performance.",
        ServiceFourStart: "Start now with us",
        ServiceFiveTitle: "FINANCIAL ADVISORY AND FEASIBILITY STUDIES",
        ServiceFiveText1:
          "We provide project owners with financial and monetary advice and prepare feasibility study based on market studies.",
        ServiceFiveText2:
          "Our accumulated experience in preparing feasibility studies has given us a prominent position in this field, as the preparation of economic feasibility studies for economic projects includes the following sections.",
        ServiceFiveMoney: "Financial and monetary advice.",
        ServiceFiveEconomy: "feasibility study.",
        ServiceSixTitle: "ACCOUNTING SOFTWARE CONSULTING",
        ServiceSixText1:
          "Extracting financial reports takes you a very long time? You do not have custom templates for invoices with the same identity as your organization and company to send to your customers in the required time when payments are due for you? .. And many of the difficulties and suffering experienced by the owners of small and medium-sized companies and entrepreneurs because they do not have the basic programs that facilitate the life of their projects.",
        ServiceSixText2:
          "Our company can do all this for you and ease the difficulties for you, so that your financial affairs are arranged, coordinated and systematic without any errors, so that you reach a clear financial vision through.",
        ServiceSixHelp:
          "Choosing the best accounting software that fits the companies needs.",
        ServiceSixImplement: "Implementation of accounting software.",
        ServiceSixFollow:
          "Financial & Accounting support after the implementation stage.",
        ServiceSevenTitle: "TRAINING SERVICES FOR CORPORATE STAFF",
        ServiceSevenText:
          "We provide training courses to our clients in many areas such as:",
        ServiceSeven1: "Management training services.",
        ServiceSeven2:
          "Financial training services such as preparing annual budgets, financial statements.",
        ServiceSeven3:
          "Training services on preparing periodic reports and dashboards.",
        ServiceSeven4:
          "Training services on bookkeeping & international accounting standards",
        ServiceSeven5:
          "Professional training services on international accounting & auditing certifications such as: CMA, CIA, CPA, DipIFR",
        OurTeamText:
          "Our team, located in Iraq, Syria and Qatar, has more than ten years of experience in the fields of accounting and auditing, and holds many professional certificates (DipIFR, MSC, CMA, CPA), which help provide the best services to customers at competitive prices.",
        FooterText:
          "This text is an example of a text that can be replaced in the same space. This text was generated from the Arabic text generator, where you can generate such text or many texts",
        OurPages: "Our Pages",
        OurClients: "Our Clients",
        OurClientsText: "Customers we are honored to provide our services to",
        CientPageFirstTitle: "Statements qualified team",
        ClientPageSecondTitle:
          "serving clients in the Middle East, Europe and the United States of America",
        ClientPageText:
          "Each industry has its NEEDS AND APPLICATIONS that distinguish it from others, and due to our team expertise , our company provides its services to all business sectors to cover ALL INDUSTRIES , for example, but not limited to:",
        ClientPageCat1: "Investment companies.",
        ClientPageCat2: "Corporate companies.",
        ClientPageCat3: "Construction companies.",
        ClientPageCat4: "Sports and social clubs.",
        ClientPageCat5: "Financial and insurance companies.",
        ClientPageCat6: "Factories and industrial businesses.",
        ClientPageCat7: "Restaurants.",
      },
    },
  },
  lng: lnglocal ?? "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
