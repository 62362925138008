import React from "react";
import { useTranslation } from "react-i18next";
const Button = ({
  bgColor,
  borderColor,
  text,
  textColor,
  dir,
  icon,
  customStyle,
  onClick,
}) => {
  const { i18n } = useTranslation();
  return (
    <div
      style={{
        backgroundColor: bgColor,
        color: textColor,
        borderRadius: 6,
        border: borderColor ? "1px solid" : 1,
        borderColor: borderColor,
      }}
      dir={dir ?? i18n.language === "en" ? "" : "rtl"}
      className={`text-center py-3  w-[100%] lg:w-[220px] m-2 flex items-center justify-center text-[16px] font-['FLight'] ${customStyle} flex cursor-pointer`}
      onClick={onClick}
    >
      <p className="px-1">{text}</p>
      {icon ?? <div className="px-1">{icon}</div>}
    </div>
  );
};

export default Button;
