import React from "react";
import { useTranslation } from "react-i18next";
import ServiceFourImage from "../../assets/images/WhoAreWe.png";
import { theme } from "../../theme";
import clipboard from "../../assets/icons/accouting/clipboard-text.svg";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
const ServiceFour = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      div
      style={{
        backgroundImage: `url(${ServiceFourImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        borderRadius: 9,
      }}
      className="lg:h-[575px] w-[100%]  bg-cover flex justify-center items-center mt-8"
      dir={i18n.language === "en" ? "" : "rtl"}
    >
      <div className="lg:grid lg:grid-cols-12">
        <div className="hidden lg:block"></div>
        <div className="col-span-4 space-y-6 mt-12 mx-2 lg:mx-0">
          <div
            className="font-['FSLight'] text-[16px]"
            style={{ color: theme.orange }}
          >
            {t("OurServices")}
          </div>
          <div className="font-['FBold'] text-[30px]">
            {t("ServiceFourTitle")}{" "}
          </div>
          <div className="font-['FSLight'] text-[14px]">
            {t("ServiceFourText")}{" "}
          </div>
        </div>
        <div className="hidden lg:block"></div>
        <div className="col-span-5">
          <div className="lg:grid grid-cols-2 gap-5 mx-8 lg:mx-0 space-y-6 lg:space-y-0 my-6 lg:my-0">
            <div
              className="p-8 space-y-8 flex flex-col justify-center items-start"
              style={{
                borderRadius: 6,
                border: "1px solid",
                borderColor: theme.lightOrange,
              }}
            >
              <div
                className="rounded p-4"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={clipboard} alt={":("} />
              </div>
              <div
                className="text-[18px] font-['FLight']"
                style={{ color: theme.orange }}
              >
                {t("ServiceFourAnnual")}
              </div>
            </div>
            <div
              className="p-8 space-y-8 flex flex-col justify-center items-start"
              style={{
                borderRadius: 6,
                border: "1px solid",
                borderColor: theme.lightOrange,
              }}
            >
              <div
                className="rounded p-4"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={clipboard} alt={":("} />
              </div>
              <div
                className="text-[18px] font-['FLight']"
                style={{ color: theme.orange }}
              >
                {t("SerivceFourAnalayze")}
              </div>
            </div>
            <div
              className="p-8 space-y-8 flex flex-col justify-center items-start"
              style={{
                borderRadius: 6,
                border: "1px solid",
                borderColor: theme.lightOrange,
              }}
            >
              <div
                className="rounded p-4"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={clipboard} alt={":("} />
              </div>
              <div
                className="text-[18px] font-['FLight']"
                style={{ color: theme.orange }}
              >
                {t("ServiceFourReport")}
              </div>
            </div>
            <div
              className="p-8 space-y-8 flex flex-col justify-center items-start"
              style={{
                borderRadius: 6,
                backgroundColor: theme.orange,
              }}
            >
              <div
                className="text-[18px] font-['FLight'] text-center self-center"
                style={{ color: "white" }}
              >
                {t("ServiceFourStart")}
              </div>
              <div className="w-[100%] flex flex-col justify-center items-center">
                <Button
                  bgColor={"white"}
                  text={t("ConactUs")}
                  textColor={theme.orange}
                  onClick={(e) => {
                    navigate("/aboutus");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ServiceFour;
