import React from "react";
import { useTranslation } from "react-i18next";
import ServicceThreeImage from "../../assets/images/servicethree.png";
import CheckIcon from "../../assets/icons/check.svg";
import { theme } from "../../theme";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import Link from "react-scroll/modules/components/Link";

const ServiceThree = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="lg:grid lg:grid-cols-12 mt-8 lg:mt-16">
      <div className="hidden lg:block"></div>
      <div
        className="col-span-5 space-y-6 mx-2 lg:mx-0"
        dir={i18n.language === "en" ? "" : "rtl"}
      >
        <div
          className=" font-['FSLight'] text-[16px]"
          style={{ color: theme.orange }}
        >
          {t("OurServices")}
        </div>
        <div className="font-['FBold'] text-[30px]">
          {t("ServiceThreeTitle")}
        </div>
        <div className="font-['FSLight'] text-[16px]">
          {t("ServiceThreeText1")}
        </div>
        <div className="font-['FSLight'] text-[16px]">
          {t("ServiceThreeText2")}
        </div>
        <div className="lg:grid grid-cols-2 gap-12 space-y-6 lg:space-y-0">
          <div className="flex justify-center items-center">
            <div>
              <img src={CheckIcon} alt={":("} />
            </div>
            <div className="mx-4 flex-1 font-['FMed'] text-[14px]">
              {t("ServiceThreeInside")}
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div>
              <img src={CheckIcon} alt={":("} />
            </div>
            <div className="mx-4 flex-1 font-['FMed'] text-[14px]">
              {t("ServiceThreeMoney")}
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div>
              <img src={CheckIcon} alt={":("} />
            </div>
            <div className="mx-4 flex-1 font-['FMed'] text-[14px]">
              {t("ServiceThreeReport")}
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start w-[100%] xl:w-min">
          <div className="w-[100%] flex flex-col justify-center items-center">
            <Button
              bgColor={theme.orange}
              text={t("ConactUs")}
              textColor={"white"}
              onClick={(e) => {
                navigate("/aboutus");
              }}
            />
          </div>
          <Link to="T" spy={true} smooth={true} style={{ width: "100%" }}>
            <div className="w-[100%] flex flex-col justify-center items-center">
              <Button
                bgColor={""}
                text={t("KnowOurTeam")}
                textColor={theme.orange}
                borderColor={theme.ButtonBorder}
                customStyle={"w-[100%]"}
              />
            </div>
          </Link>
        </div>
      </div>
      <div className=""></div>

      <div
        className="col-span-5 my-3 lg:my-0 h-[350px] bg-contain lg:h-[500px] lg:bg-contain"
        style={{
          backgroundImage: `url(${ServicceThreeImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      ></div>
    </div>
  );
};

export default ServiceThree;
