import React from "react";
import { useTranslation } from "react-i18next";
import OurTeamImage from "../../assets/images/ourteam.png";
import OurTeamImageRes from "../../assets/images/OurTeamRes.png";
// import man from "../../assets/images/intro.png";
import AhmadHabbal from "../../assets/images/Ahmad.png";
import Eyad from "../../assets/images/Eyad.png";
import Pulse from "react-reveal/Pulse";

const OurTeam = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className=" hidden lg:block ">
        <div
          className="h-[850px] 2xl:h-[1085px] bg-contain flex flex-col justify-start items-center text-[white] text-center"
          style={{
            backgroundImage: `url(${OurTeamImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        >
          <div className="font-['FBold'] text-[40px] mt-14 lg:mt-10 2xl:mt-20">
            {t("OurTeam")}
          </div>

          <div className="lg:grid lg:grid-cols-2 gap-24 mt-6 lg:mt-1 2xl:mt-20">
            <Pulse>
              <div className="rounded  ">
                <img
                  src={AhmadHabbal}
                  alt={":("}
                  className="w-[350px] h-[323px] 2xl:w-[350px] 2xl:h-[400px] rounded object-cover object-top hover:scale-125 hover:pb-3"
                  style={{
                    transition: "0.5s",
                  }}
                />
                <div className="py-6 px-4  ">
                  <div className="font-['FBold'] text-[22px] text-[#EBF0F1] text-center">
                    Ahmed Al Habbal {"(CMA)"}
                  </div>
                  <div className="font-['FLight'] text-[16px] text-[#C0C8CA] text-center">
                    Finance Director
                  </div>
                </div>
              </div>
            </Pulse>
            <Pulse>
              <div className="">
                <img
                  src={Eyad}
                  alt={":("}
                  className="w-[350px] h-[323px] 2xl:w-[350px] 2xl:h-[400px] rounded object-cover object-top  hover:scale-125 hover:pb-3"
                  style={{
                    transition: "0.5s",
                  }}
                />
                <div className="py-6 px-4">
                  <div className="font-['FBold'] text-[22px] text-[#EBF0F1] text-center">
                    Eyad Al-Turk {"(MSC)"}
                  </div>
                  <div className="font-['FLight'] text-[16px] text-[#C0C8CA] text-center">
                    Managing Director
                  </div>
                </div>
              </div>
            </Pulse>
          </div>
          <div className="font-['FBold'] text-[20px] w-[80%] ">
            {t("OurTeamText")}
          </div>
        </div>
      </div>
      {/* mobile view */}
      <div className="lg:hidden ">
        <div
          className="py-16 px-2 bg-cover flex flex-col justify-start items-center text-[white] text-center"
          style={{
            backgroundImage: `url(${OurTeamImageRes})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        >
          <div className="font-['FBold'] text-[30px] mt-8">{t("OurTeam")}</div>

          <div className="mt-12 space-y-8">
            <div className="">
              <img
                src={AhmadHabbal}
                alt={":("}
                className="w-[302px] h-[250px] rounded object-cover object-top l"
              />
              <div className="py-4 px-3">
                <div className="font-['FBold'] text-[22px] text-[#EBF0F1] text-center">
                  Ahmed Al Habbal {"(CMA)"}
                </div>
                <div className="font-['FLight'] text-[16px] text-[#C0C8CA] text-center">
                  Finance Director
                </div>
              </div>
            </div>
            <div className="">
              <img
                src={Eyad}
                alt={":("}
                className="w-[302px] h-[250px] rounded object-cover object-top "
              />
              <div className="py-4 px-3">
                <div className="font-['FBold'] text-[22px] text-[#EBF0F1] text-center">
                  Eyad Al-Turk {"(MSC)"}
                </div>
                <div className="font-['FLight'] text-[16px] text-[#C0C8CA] text-center">
                  Managing Director
                </div>
              </div>
            </div>
          </div>
          <div className="font-['FBold'] text-[20px] w-[90%] mt-3 ">
            {t("OurTeamText")}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
