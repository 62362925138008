import React from "react";
import HomeIntro from "../components/HomeIntro/HomeIntro";
import WhoAreWe from "../components/WhoAreWe/WhoAreWe";
import ServiceOne from "../components/ServiceOne/ServiceOne";
import ServiceTwo from "../components/ServiceTwo/ServiceTwo";
import ServiceThree from "../components/ServiceThree/ServiceThree";
import ServiceFour from "../components/ServiceFour/ServiceFour";
import ServiceFive from "../components/ServiceFive/ServiceFive";
import ServiceSix from "../components/ServiceSix/ServiceSix";
import ServiceSeven from "../components/ServiceSeven/ServiceSeven";
import OurTeam from "../components/OurTeam/OurTeam";
import OurClients from "../components/OurClients/OurClients";
const Home = () => {
  return (
    <div>
      <div id="H"></div>
      <HomeIntro />
      <div id="WAW"></div>
      <WhoAreWe />
      <div id="S"></div>
      <ServiceOne />
      <div id="stwo"></div>
      <ServiceTwo />
      <div id="sthree"></div>
      <ServiceThree />
      <div id="sfour"></div>
      <ServiceFour />
      <div id="sfive"></div>
      <ServiceFive />
      <div id="ssix"></div>
      <ServiceSix />
      <ServiceSeven />
      <div id="T"></div>
      <OurTeam />
      <OurClients bg={"bg-[#EBF0F1]"} seeMore />
    </div>
  );
};

export default Home;
