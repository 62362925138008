import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/Logo.png";
import { FacebookOutlined, Instagram, LinkedIn } from "@mui/icons-material";
import { theme } from "../../theme";
import { useNavigate } from "react-router-dom";
import Link from "react-scroll/modules/components/Link";
import call from "../../assets/icons/contactus/call.svg";
import Vector from "../../assets/icons/contactus/Vector.svg";
const Footer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="lg:grid lg:grid-cols-12 bg-[#EBF0F1] py-8"
      dir={i18n.language === "en" ? "" : "rtl"}
    >
      <div className="hidden lg:block"></div>
      <div className="col-span-3 flex flex-col justify-start items-center lg:items-start">
        <img src={Logo} alt={":("} className="lg:w-[250px] lg:h-[120px]" />
        <div className="font-['FSLight'] text-[14px] py-3 mx-5 lg:mx-0">
          {t("HomeIntroText")}
        </div>
      </div>
      <div className="hidden lg:block"></div>
      <div className="col-span-3 flex flex-col justify-start items-center lg:items-start space-y-6 mt-6">
        <div className="font-['FBold'] text-[26px]">
          {i18n.language == "en" ? "Follow Us On" : "تواصل معنا"}
        </div>

        <div className="flex flex-col justify-start items-start space-y-7 ">
          <div className="flex flex-col lg:flex-row justify-center lg:justify-start items-start lg:items-center">
            <div
              className="flex justify-center items-start cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  "https://www.facebook.com/people/Statementsmea/100085533019751/",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              <FacebookOutlined
                style={{ color: theme.orange }}
                fontSize={"medium"}
              />
              {i18n.language === "en" ? (
                <div className="text-[16px] font-['FSLight'] mx-2">
                  Facebook
                </div>
              ) : (
                <div className="text-[16px] font-['FSLight'] mx-2">
                  {" فيسبوك "}
                </div>
              )}
            </div>

            <div
              className="flex justify-center items-start cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  "https://www.linkedin.com/company/statementsmea/",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              <LinkedIn style={{ color: theme.orange }} />
              {i18n.language === "en" ? (
                <div className="text-[16px] font-['FSLight'] mx-2">
                  LinkedIn
                </div>
              ) : (
                <div className="text-[16px] font-['FSLight'] mx-2">
                  {" لينكد ان "}
                </div>
              )}
            </div>
            <div
              className="flex  justify-center items-start cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  "https://www.instagram.com/statementsmea/",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              <Instagram style={{ color: theme.orange }} />
              {i18n.language === "en" ? (
                <div className="text-[16px] font-['FSLight'] mx-2">
                  Instagram
                </div>
              ) : (
                <div className="text-[16px] font-['FSLight'] mx-2">
                  {" انستاغرام "}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-start items-center">
            <img src={Vector} alt={"."} className="h-[20px] w-[20px] " />
            <div className="font-['FSLight'] text-[16px] px-4">
              info@statementsco.com
            </div>
          </div>
          <div className="flex flex-row justify-start items-center">
            <img src={call} alt={"."} className="h-[20px] w-[20px]" />
            <div dir="ltr" className="font-['FSLight'] text-[16px] px-4">
              <p>+964-7512354508</p>
              <p>+964-7508590700</p>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden lg:block"></div>

      <div className=" mx-5 lg:mx-0 col-span-3 flex flex-col justify-start items-center lg:items-start space-y-3 mt-6 ">
        <div className="font-['FBold'] text-[26px]">{t("OurServices")} </div>
        <div className="lg:grid lg:grid-cols-1 gap-2">
          <Link to="S" spy={true} smooth={true}>
            <div
              className="flex text-[16px] font-['FSLight'] cursor-pointer hover:text-[#C54A31] hover:text-[18px]"
              style={{ transition: "0.3s" }}
              onClick={() => navigate("/")}
            >
              <span className="font-['FBold']">{"> "}</span>

              <div className="px-1">{t("ServiceOneTitle")}</div>
            </div>
          </Link>
          <Link to="stwo" spy={true} smooth={true}>
            <div
              className="flex text-[16px] font-['FSLight'] cursor-pointer hover:text-[#C54A31] hover:text-[18px]"
              style={{ transition: "0.3s" }}
              onClick={() => navigate("/")}
            >
              <span className="font-['FBold']">{"> "}</span>
              <div className="px-1">{t("ServiceTwoTitle")}</div>
            </div>
          </Link>
          <Link to="sthree" spy={true} smooth={true}>
            <div
              className="flex text-[16px] font-['FSLight'] cursor-pointer hover:text-[#C54A31] hover:text-[18px]"
              style={{ transition: "0.3s" }}
              onClick={() => navigate("/")}
            >
              <span className="font-['FBold'] ">{"> "}</span>
              <div className="px-1">{t("ServiceThreeTitle")}</div>
            </div>
          </Link>
          <Link to="sfour" spy={true} smooth={true}>
            <div
              className="flex text-[16px] font-['FSLight'] cursor-pointer hover:text-[#C54A31] hover:text-[18px]"
              style={{ transition: "0.3s" }}
              onClick={() => navigate("/")}
            >
              <span className="font-['FBold']">{"> "}</span>
              <div className="px-1">{t("ServiceFourTitle")}</div>
            </div>
          </Link>
          <Link to="sfive" spy={true} smooth={true}>
            <div
              className="flex text-[16px] font-['FSLight'] cursor-pointer hover:text-[#C54A31] hover:text-[18px]"
              style={{ transition: "0.3s" }}
              onClick={() => navigate("/")}
            >
              <span className="font-['FBold']">{"> "}</span>
              <div className="px-1">{t("ServiceFiveTitle")}</div>
            </div>
          </Link>
          <Link to="ssix" spy={true} smooth={true}>
            <div
              className="flex text-[16px] font-['FSLight'] cursor-pointer hover:text-[#C54A31] hover:text-[18px]"
              style={{ transition: "0.3s" }}
              onClick={() => navigate("/")}
            >
              <span className="font-['FBold']">{"> "}</span>
              <div className="px-1">{t("ServiceSixTitle")}</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
