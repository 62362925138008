import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import { theme } from "../../theme/index";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import Link from "react-scroll/modules/components/Link";
import { IconButton, MenuItem, Menu, Box, List, Drawer } from "@mui/material";
import {
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
  LanguageOutlined,
} from "@mui/icons-material";

const NavBar = (props) => {
  const { window } = props;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [header, setHeader] = useState("transparent");

  const isLanguageMenuOpen = Boolean(languageAnchorEl);

  const handleLanguageMenuOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    sessionStorage.setItem("lng", lng);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const listenScrollEvent = (event) => {
    if (document.documentElement.scrollTop < 100) {
      return setHeader("rgba(235,240,241,0.5)");
    } else if (document.documentElement.scrollTop > 97) {
      return setHeader(theme.darkWhite);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", listenScrollEvent);

    return () => {
      document.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
      style={{ backgroundColor: theme.darkBlue }}
    >
      <List>
        <div className="flex justify-start " onClick={() => navigate("/")}>
          <img className="cursor-pointer" src={Logo} alt="LOGO" />
        </div>
        <Link to="H" spy={true} smooth={true}>
          <div
            onClick={() => {
              navigate("/");
              handleDrawerToggle();
            }}
            className="px-4 py-3 cursor-pointer font-[FMed] text-[16px] text-center text-[#EBF0F1]"
          >
            {t("Home")}
          </div>
        </Link>
        <Link to="WAW" spy={true} smooth={true}>
          <div
            className="px-4 py-3 cursor-pointer  font-[FMed] text-[16px] text-center text-[#EBF0F1]"
            onClick={() => navigate("/")}
          >
            {t("WhoAreWe")}
          </div>
        </Link>
        <Link to="S" spy={true} smooth={true}>
          <div
            onClick={() => navigate("/")}
            className="px-4 py-3 cursor-pointer font-[FMed] text-[16px] text-center text-[#EBF0F1]"
          >
            {t("OurServices")}
          </div>
        </Link>
        <Link to="T" spy={true} smooth={true}>
          <div
            onClick={() => navigate("/")}
            className="px-4 py-3 cursor-pointer font-[FMed] text-[16px] text-center text-[#EBF0F1]"
          >
            {t("OurTeam")}
          </div>
        </Link>
        <div
          onClick={() => navigate("/clients")}
          className="px-4 py-3 cursor-pointer font-[FMed] text-[16px] text-center text-[#EBF0F1]"
        >
          {t("OurClients")}
        </div>
        <div
          onClick={() => navigate("/aboutus")}
          className="px-4 py-3 cursor-pointer font-[FMed] text-[16px] text-center text-[#EBF0F1]"
        >
          {t("ConactUs")}
        </div>
      </List>
    </Box>
  );
  const menuId = "primary-search-account-menu";

  const languageRenderMenu = (
    <Menu
      anchorEl={languageAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isLanguageMenuOpen}
      onClose={handleLanguageMenuClose}
      style={{ marginTop: "2rem" }}
      PaperProps={{ style: { backgroundColor: theme.darkWhite } }}
    >
      <div>
        <MenuItem
          onClick={() => {
            changeLanguage("en");
            setLanguageAnchorEl(null);
          }}
        >
          <p className="mx-2 flex-1 text-[#C54A31] font-[FMed]">
            {t("English")}
          </p>
          {i18n.language === "en" ? (
            <RadioButtonCheckedOutlined className={`text-[#C54A31]`} />
          ) : (
            <RadioButtonUncheckedOutlined className={`text-[#C54A31]`} />
          )}
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeLanguage("ar");
            setLanguageAnchorEl(null);
          }}
        >
          <p className="mx-2 flex-1 text-[#C54A31] font-[FMed]">
            {t("Arabic")}
          </p>
          {i18n.language === "ar" ? (
            <RadioButtonCheckedOutlined className={`text-[#C54A31]`} />
          ) : (
            <RadioButtonUncheckedOutlined className={`text-[#C54A31]`} />
          )}
        </MenuItem>
      </div>
    </Menu>
  );

  const headerLinkStyle =
    "col-span-0 lg:col-span-1 cursor-pointer hidden lg:block font-[FMed] text-[16px] text-center ";
  return (
    // sticky top-0 z-50

    <div
      style={{
        position: "fixed",
        backgroundColor: header,
        transition: "0.3s",
      }}
      dir={i18n.language === "en" ? "" : "rtl"}
      className={`z-50 top-0 w-full ${
        header === theme.darkWhite ? "shadow-2xl" : ""
      }`}
    >
      <div
        className={`mx-[1%] grid grid-cols-10 ${
          header === theme.darkWhite ? "py-0" : "py-0"
        } items-center text-start`}
        style={{
          transition: "0.3s",
        }}
      >
        <div
          className="col-span-4 lg:col-span-2 lg:mx-8 2xl:mx-20 flex justify-start "
          onClick={() => navigate("/")}
        >
          <img
            className={`cursor-pointer ${
              header === theme.darkWhite ? "-my-4" : "-my-3"
            }`}
            style={{ transition: "0.3s" }}
            src={Logo}
            alt="LOGO"
          />
        </div>
        <Link to="H" spy={true} smooth={true} className={headerLinkStyle}>
          <div onClick={() => navigate("/")}>{t("Home")}</div>
        </Link>
        <Link to="WAW" spy={true} smooth={true} className={headerLinkStyle}>
          <div onClick={() => navigate("/")}>{t("WhoAreWe")}</div>
        </Link>
        <Link to="S" spy={true} smooth={true} className={headerLinkStyle}>
          <div onClick={() => navigate("/")}>{t("OurServices")}</div>
        </Link>
        <Link to="T" spy={true} smooth={true} className={headerLinkStyle}>
          <div onClick={() => navigate("/")}>
            {i18n.language == "en" ? "Founders" : t("OurTeam")}
          </div>
        </Link>
        <div onClick={() => navigate("/clients")} className={headerLinkStyle}>
          {t("OurClients")}
        </div>
        <div onClick={() => navigate("/aboutus")} className={headerLinkStyle}>
          {t("ConactUs")}
        </div>
        <div></div>
        <div className={`rounded-full mx-2`}>
          <IconButton
            edge="center"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleLanguageMenuOpen}
            color="inherit"
          >
            <LanguageOutlined className="text-[#C54A31]" />
          </IconButton>
        </div>
        <div className="lg:hidden"></div>

        <div
          className={`rounded-full mx-2  lg:hidden col-span-1 lg:col-span-0 `}
        >
          <IconButton
            color="inherit"
            edge="center"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleDrawerToggle}
          >
            <MenuIcon className="text-[#C54A31]" />
          </IconButton>
        </div>

        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 250,
              backgroundColor: theme.darkBlue,
            },
          }}
        >
          {drawer}
        </Drawer>
        {languageRenderMenu}
      </div>
    </div>
  );
};

export default NavBar;
