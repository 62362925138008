import { Divider } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import AIM from "../../assets/images/Clients/AIM.png";
import ATGroup from "../../assets/images/Clients/ATGroup.png";
import LG from "../../assets/images/Clients/LG.png";
import Sukkar from "../../assets/images/Clients/Sukkar.jpeg";
import SN from "../../assets/images/Clients/SN.jpeg";
import Mansur from "../../assets/images/Clients/Mansur.jpeg";
import { Fade } from "react-reveal";
import Button from "../Button/Button";
import { theme } from "../../theme/index";
import { useNavigate } from "react-router-dom";
const OurClients = ({ bg, seeMore }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className={`${bg} flex flex-col justify-center items-center py-6 text-center space-y-9`}
      style={{ transition: "0.3s" }}
    >
      <div className="font-['FLight'] text-[16px] 2xl:text-[26px]">
        {t("OurClients")}
      </div>
      <div className="font-['FBold'] text-[30px] lg:text-[40px]">
        {t("OurClientsText")}
      </div>
      <Fade bottom cascade>
        <div
          className="mx-4 lg:mx-0 grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-12 lg:gap-12 2xl:gap-16"
          style={{ transition: "0.3s" }}
        >
          <div
            className="flex justify-center items-center rounded-xl hover:scale-150 bg-[#193F54] shadow-2xl "
            style={{ transition: "0.3s" }}
          >
            <img
              src={LG}
              alt={":("}
              className="rounded-xl h-[100px] w-[200px] md:h-[150px] md:w-[250px]"
              style={{ transition: "0.3s" }}
            />
          </div>
          <div
            className="flex justify-center items-center rounded-xl hover:scale-150 shadow-2xl "
            style={{ transition: "0.3s" }}
          >
            <img
              src={AIM}
              alt={":("}
              className="rounded-xl h-[100px] w-[200px] md:h-[150px] md:w-[250px]"
              style={{ transition: "0.3s" }}
            />
          </div>

          <div
            className="flex justify-center items-center rounded-xl hover:scale-150 shadow-2xl"
            style={{ transition: "0.3s" }}
          >
            <img
              src={Sukkar}
              alt={":("}
              className="rounded-xl h-[100px] w-[200px] md:h-[150px] md:w-[250px]"
              style={{ transition: "0.3s" }}
            />
          </div>

          <div
            className="flex justify-center items-center rounded-xl block hover:scale-150 bg-[#193F54] shadow-2xl "
            style={{ transition: "0.3s" }}
          >
            <img
              src={ATGroup}
              alt={":("}
              className="rounded-xl h-[100px] w-[200px] md:h-[150px] md:w-[250px]"
              style={{ transition: "0.3s" }}
            />
          </div>
          <div
            className="flex justify-center items-center rounded-xl block hover:scale-150 2xl:col-span-2"
            style={{ transition: "0.3s" }}
          >
            <img
              src={SN}
              alt={":("}
              className="rounded-xl h-[100px] w-[200px] md:h-[150px] md:w-[250px] shadow-2xl"
              style={{ transition: "0.3s" }}
            />
          </div>
          <div
            className="flex justify-center items-center rounded-xl block hover:scale-150 2xl:col-span-2"
            style={{ transition: "0.3s" }}
          >
            <img
              src={Mansur}
              alt={":("}
              className="rounded-xl h-[100px] w-[200px] md:h-[150px] md:w-[250px] shadow-2xl"
              style={{ transition: "0.3s" }}
            />
          </div>
          {seeMore && (
            <div
              className="flex justify-center items-center rounded-xl block hover:scale-150 2xl:col-span-4"
              style={{ transition: "0.3s" }}
            >
              <div
                style={{ backgroundColor: theme.orange, transition: "0.3s" }}
                className="rounded-xl h-[100px] w-[200px] md:h-[150px] md:w-[250px] shadow-2xl flex flex-col justify-center items-center"
              >
                <div
                  style={{ transition: "0.3s" }}
                  className={`text-[${theme.orange}] bg-[${theme.darkWhite}] h-[70%] w-[75%] hover:h-[90%] hover:w-[95%] shadow-2xl rounded-xl flex justify-center items-center font-[FBold] text-2xl cursor-pointer`}
                  onClick={() => navigate("/clients")}
                >
                  {i18n.language == "en" ? "View More" : "أعرض المزيد"}
                </div>
              </div>
            </div>
          )}
        </div>
      </Fade>
      <div className="w-full px-[10%] ">
        <Divider />
      </div>
    </div>
  );
};

export default OurClients;
