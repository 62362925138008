import React from "react";
import ServiceOneImage from "../../assets/images/serviceone.png";
import book from "../../assets/icons/accouting/book.svg";
import coin from "../../assets/icons/accouting/coin.svg";
import graph from "../../assets/icons/accouting/graph.svg";
import data from "../../assets/icons/accouting/data-2.svg";
import fchart from "../../assets/icons/accouting/favorite-chart.svg";
import clipboard from "../../assets/icons/accouting/clipboard-text.svg";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme/index";
import Button from "../Button/Button";
import pdf from "../../assets/Pdfs/ServiceOnePDF.pdf";
const ServiceOne = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="lg:grid lg:grid-cols-12 mt-8 lg:mt-32">
      <div className="col-span-4 hidden lg:block">
        <div
          className=" lg:h-[600px] 2xl:w-[575px] flex flex-col justify-center items-start bg-contain 2xl:bg-cover"
          style={{
            backgroundImage: `url(${ServiceOneImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        ></div>
      </div>
      <div className="hidden lg:block"></div>
      <div
        className="col-span-6 space-y-4 mx-2 lg:mx-0"
        dir={i18n.language === "en" ? "" : "rtl"}
      >
        <div
          className="font-['FLight'] text-[16px]"
          style={{ color: theme.orange }}
        >
          {t("OurServices")}
        </div>
        <div className="font-['FBold'] text-[30px]">{t("ServiceOneTitle")}</div>
        <div className="font-['FLight'] text-[16px] text-[#8E8E8E]">
          {t("ServiceOneText")}
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-6 space-y-6 lg:space-y-0">
          <div className="flex justify-start items-center">
            <div
              className="rounded p-4"
              style={{ backgroundColor: theme.lightOrange }}
            >
              <img src={data} alt={":("} />
            </div>
            <div className="mx-4 text-[16px] font-['FMed'] flex-1">
              {t("ServiceOneDesign")}
            </div>
          </div>
          <div className="flex justify-start items-center">
            <div
              className="rounded p-4"
              style={{ backgroundColor: theme.lightOrange }}
            >
              <img src={clipboard} alt={":("} />
            </div>
            <div className="mx-4 text-[16px] font-['FMed'] flex-1">
              {t("ServiceOneMenu")}
            </div>
          </div>
          <div className="flex justify-start items-center">
            <div
              className="rounded p-4"
              style={{ backgroundColor: theme.lightOrange }}
            >
              <img src={book} alt={":("} />
            </div>
            <div className="mx-4 text-[16px] font-['FMed'] flex-1">
              {t("ServiceOneSallery")}
            </div>
          </div>
          <div className="flex justify-start items-center">
            <div
              className="rounded p-4"
              style={{ backgroundColor: theme.lightOrange }}
            >
              <img src={coin} alt={":("} />
            </div>
            <div className="mx-4 text-[16px] font-['FMed'] flex-1">
              {t("ServiceOneTaxes")}
            </div>
          </div>
          <div className="flex justify-start items-center">
            <div
              className="rounded p-4"
              style={{ backgroundColor: theme.lightOrange }}
            >
              <img src={graph} alt={":("} />
            </div>
            <div className="mx-4 text-[16px] font-['FMed'] flex-1">
              {t("ServiceOneReport")}
            </div>
          </div>
          <div className="flex justify-start items-center">
            <div
              className="rounded p-4"
              style={{ backgroundColor: theme.lightOrange }}
            >
              <img src={fchart} alt={":("} />
            </div>
            <div className="mx-4 text-[16px] font-['FMed'] flex-1">
              {t("ServiceOneStorage")}
            </div>
          </div>
        </div>
        <div className="w-[100%] flex flex-row justify-start items-center lg:items-start">
          <Button
            bgColor={theme.orange}
            text={
              i18n.language == "en" ? "Our business models " : "نماذج أعمالنا"
            }
            textColor={"white"}
            onClick={(e) => {
              e.preventDefault();
              window.open(pdf, "_blank", "noopener,noreferrer");
            }}
          />
        </div>
      </div>
      <div className=""></div>
    </div>
  );
};

export default ServiceOne;
