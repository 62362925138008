import React from "react";
import Map from "../assets/images/ClientsMap.png";
import OurClientsHome from "../components/OurClients/OurClients";
import CheckIcon from "../assets/icons/check.svg";
import { Divider } from "@mui/material";
import { theme } from "../theme";
import { useTranslation } from "react-i18next";

const ClientsCategories = ({ category }) => {
  return (
    <div className="flex justify-center items-center lg:px-3 2xl:px-8">
      <div>
        <img src={CheckIcon} alt={":("} />
      </div>
      <div className="mx-4 flex-1 font-['FMed'] text-[16px] 2xl:text-[18px]">
        {category}
      </div>
    </div>
  );
};
const OurClients = () => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className="mb-12 mt-44 lg:mt-20  mx-4 lg:mx-[5%]"
      dir={i18n.language == "en" ? "" : "rtl"}
    >
      <div className="flex flex-col justify-center items-center text-center">
        <p
          className="font-['FMED'] text-[20px] lg:text-[24px] 2xl:text-[30px]"
          style={{ color: theme.orange }}
        >
          {t("CientPageFirstTitle")}
        </p>
        <p className="font-['FBold'] text-[20px] lg:text-[30px]">
          {t("ClientPageSecondTitle")}
        </p>
        <div
          className="h-[300px] lg:h-[450px] bg-contain w-full mt-12"
          style={{
            backgroundImage: `url(${Map})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      </div>
      <div className="w-full px-[10%] py-[3%] ">
        <Divider />
      </div>
      <div className="font-['FMed'] text-[18px] lg:text-[20px] 2xl:text-[26px] text-center mt-8">
        {t("ClientPageText")}
      </div>
      <div className="flex items-center justify-center mt-8 ">
        <div className="lg:grid lg:grid-cols-12 space-y-6 lg:space-y-0 ">
          <div className="lg:col-span-4 2xl:col-span-3 space-y-6">
            <ClientsCategories category={t("ClientPageCat1")} />
            <ClientsCategories category={t("ClientPageCat2")} />
          </div>
          <div className="lg:col-span-4 2xl:col-span-3 space-y-6">
            <ClientsCategories category={t("ClientPageCat3")} />
            <ClientsCategories category={t("ClientPageCat4")} />
          </div>
          <div className="lg:col-span-4 2xl:col-span-3 space-y-6">
            <ClientsCategories category={t("ClientPageCat5")} />
            <ClientsCategories category={t("ClientPageCat6")} />
          </div>
          <div className="lg:col-span-4 2xl:col-span-3 space-y-6 lg:pt-6 2xl:pt-0">
            <ClientsCategories category={t("ClientPageCat7")} />
          </div>
        </div>
      </div>
      <div className="w-full px-[10%] py-[3%] ">
        <Divider />
      </div>
      <OurClientsHome />
    </div>
  );
};

export default OurClients;
