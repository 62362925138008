import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import OurClients from "./pages/OurClients";
const NotFound = () => {
  const { i18n } = useTranslation();
  return (
    <main className="flex flex-row justify-center items-center min-h-screen">
      <div className="bg-[#193F54] rounded-3xl shadow-2xl">
        {i18n.language === "en" ? (
          <p className="text-6xl p-52 text-[white]">404 Page Not Found!</p>
        ) : (
          <p className="text-6xl p-52 text-[white]">
            404 لم يتم العثور على الصفحة
          </p>
        )}
      </div>
    </main>
  );
};
const App = () => {
  return (
    <Router>
      <NavBar />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/clients" element={<OurClients />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
