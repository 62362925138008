import React from "react";
import { useTranslation } from "react-i18next";
// import Button from "../components/Button/Button";
import { theme } from "../theme";
import user from "../assets/icons/contactus/frame.svg";
import call from "../assets/icons/contactus/call.svg";
import location from "../assets/icons/contactus/location.svg";
import Vector from "../assets/icons/contactus/Vector.svg";
import map from "../assets/images/map.png";
import { FacebookOutlined, Instagram, LinkedIn } from "@mui/icons-material";
import emailjs from "emailjs-com";
const AboutUs = () => {
  const { i18n } = useTranslation();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_6lq3itk",
        "template_t1olyyh",
        e.target,
        "1QcScNG9RpAGBwe2Q"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="mb-12 mt-44 lg:mt-20 mx-4 lg:mx-0">
      <div className="font-['FLight'] text-[22px] text-center text-[#C54A31]">
        {i18n.language == "en" ? "Let us talk" : "دعنا نتحدث"}{" "}
      </div>
      <div className="font-['FBold'] text-[46px] text-center">
        {i18n.language == "en" ? "Happy To Communicate" : " سعيدون بالتواصل"}
      </div>
      <div
        className="lg:grid grid-cols-12 gap-12 mt-12 space-y-5 lg:space-y-0"
        dir={i18n.language === "en" ? "" : "rtl"}
      >
        <div></div>
        <div className="col-span-5 flex flex-col justify-start items-center bg-[#EAEAEA] rounded p-12 ">
          <form className="w-full" onSubmit={sendEmail}>
            <div className="mb-6">
              <div className="flex justifty-center items-center bg-[white] rounded">
                <div className="pr-4 pl-2">
                  <img src={user} alt={"."} />
                </div>
                <input
                  type="userName"
                  id="userName"
                  name="userName"
                  className="bg-[white] text-[#C54A31] rounded-lg block w-full p-2.5 py-5"
                  placeholder={
                    i18n.language == "en" ? "Full Name" : "الأسم الكامل"
                  }
                  required
                />
              </div>
            </div>
            <div className="mb-6">
              <div className="flex justifty-center items-center bg-[white] rounded">
                <div className="pr-4 pl-2">
                  <img src={Vector} alt={"."} />
                </div>
                <input
                  type="emailAddress"
                  id="emailAddress"
                  name="emailAddress"
                  className="bg-[white] text-[#C54A31] rounded-lg block w-full p-2.5 py-5"
                  placeholder={
                    i18n.language == "en" ? "Email" : "البريد الالكتروني"
                  }
                  required
                />
              </div>
            </div>
            <div className="mb-6">
              <textarea
                type="textarea"
                id="message"
                name="message"
                className="bg-[white] text-[#C54A31] rounded-lg block w-full p-2.5 py-5 px-4"
                placeholder={i18n.language == "en" ? "Message" : "نص الرسالة"}
                required
                rows={9}
              />
            </div>
            <div className="w-[100%] flex flex-col justify-center items-end">
              <input
                type={"submit"}
                value={i18n.language == "en" ? "Send" : "ارسال"}
                style={{
                  backgroundColor: theme.orange,
                  color: "white",
                  borderRadius: 6,
                }}
                className={`text-center py-3  w-[50%] flex items-center justify-center text-[16px] font-['FLight'] flex cursor-pointer`}
              />
            </div>
          </form>
        </div>
        <div className="col-span-5 flex flex-col justify-start items-start space-y-7 ">
          <div className="flex flex-row justify-start items-center">
            <div className="mx-2 bg-[rgba(197,74,49,0.2)] rounded p-3">
              <img src={location} alt={"."} className="h-[22px] w-[22px]" />
            </div>
            <div className="mx-2 font-['FLight'] text-[16px]">
              Justice tower - Floor 13 - Office 23 - Erbil - Iraq
            </div>
          </div>
          <div className="flex flex-row justify-start items-center">
            <div className="mx-2 bg-[rgba(197,74,49,0.2)] rounded p-3">
              <img src={Vector} alt={"."} className="h-[22px] w-[22px]" />
            </div>
            <div className="mx-2 font-['FLight'] text-[16px]">
              info@statementsco.com
            </div>
          </div>
          <div className="flex flex-row justify-start items-center">
            <div className="mx-2 bg-[rgba(197,74,49,0.2)] rounded p-3">
              <img src={call} alt={"."} className="h-[22px] w-[22px]" />
            </div>
            <div dir="ltr" className="mx-2 font-['FLight'] text-[16px]">
              +964-7512354508{" - "} +964-7508590700
            </div>
          </div>
          <div className="font-['FLight'] text-[16px]">
            {i18n.language == "en" ? "Follow Us On" : " تابعنا على"}
          </div>
          <div className="flex justify-center items-center">
            <div className="mx-2 bg-[rgba(197,74,49,0.2)] rounded p-3">
              <div
                className="flex  justify-center items-start cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://www.instagram.com/statementsmea/",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <Instagram style={{ color: theme.orange }} />
              </div>
            </div>

            <div className="mx-2 bg-[rgba(197,74,49,0.2)] rounded p-3">
              <div
                className="flex justify-center items-start cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://www.facebook.com/people/Statementsmea/100085533019751/",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <FacebookOutlined style={{ color: theme.orange }} />
              </div>
            </div>
            <div className="mx-2 bg-[rgba(197,74,49,0.2)] rounded p-3">
              <div
                className="flex justify-center items-start cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://www.linkedin.com/in/statements-mea-a1171424b/",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <LinkedIn style={{ color: theme.orange }} />
              </div>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              window.open(
                "https://www.google.com/maps/place/Justice+Tower/@36.181701,43.978611,20z/data=!4m5!3m4!1s0x0:0x9ad17e6b21d0bcb5!8m2!3d36.1816866!4d43.9786101?hl=en-US",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            className="cursor-pointer"
          >
            <img src={map} alt={":("} />
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default AboutUs;
