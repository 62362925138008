import React from "react";
import ServiceFiveImage from "../../assets/images/servicefive.png";
import book from "../../assets/icons/accouting/book.svg";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme";
import Button from "../Button/Button";
import ServiceFivePDF from "../../assets/Pdfs/ServiceFivePDF.pdf";
const ServiceFive = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="lg:grid lg:grid-cols-12 mt-8 lg:mt-24">
      <div
        className="col-span-5 2xl:col-span-4 h-[450px] lg:h-[650px] 2xl:h-[675px] bg-contain"
        style={{
          backgroundImage: `url(${ServiceFiveImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      ></div>

      <div className="hidden 2xl:block"></div>
      <div className="hidden lg:block"></div>
      <div
        className="col-span-5 space-y-4 mx-2 lg:mx-0"
        dir={i18n.language === "en" ? "" : "rtl"}
      >
        <div
          className="font-['FSLight'] text-[16px]"
          style={{ color: theme.orange }}
        >
          {t("OurServices")}
        </div>
        <div className="font-['FBold'] text-[30px]">
          {t("ServiceFiveTitle")}
        </div>
        <div className="font-['FLight'] text-[16px] text-[#8E8E8E] py-2">
          {t("ServiceFiveText1")}
        </div>
        <div className="font-['FLight'] text-[16px] text-[#8E8E8E] py-2">
          {t("ServiceFiveText2")}
        </div>
        <div className="lg:grid grid-cols-2 gap-6 space-y-6 lg:space-y-0">
          <div className="flex justify-center items-center">
            <div
              className="rounded p-2"
              style={{ backgroundColor: theme.lightOrange }}
            >
              <img src={book} alt={":("} />
            </div>
            <div className="mx-4 flex-1 font-['FMed'] text-[16px]">
              {t("ServiceFiveMoney")}
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div
              className="rounded p-2"
              style={{ backgroundColor: theme.lightOrange }}
            >
              <img src={book} alt={":("} />
            </div>
            <div className="mx-4 flex-1 font-['FMed'] text-[16px]">
              {t("ServiceFiveEconomy")}
            </div>
          </div>
        </div>
        <div className="w-[100%] flex flex-col justify-center items-center lg:items-start">
          <Button
            bgColor={theme.orange}
            text={
              i18n.language == "en" ? "Our business models" : "نماذج أعمالنا"
            }
            textColor={"white"}
            onClick={(e) => {
              e.preventDefault();
              window.open(ServiceFivePDF, "_blank", "noopener,noreferrer");
            }}
          />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ServiceFive;
