import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import WhoAreWeBack from "../../assets/images/WhoAreWe.png";
import { theme } from "../../theme";
import SearchStatus from "../../assets/icons/search-status.svg";
// import MedalStar from "../../assets/icons/medal-star.svg";
import Chart from "../../assets/icons/chart.svg";
const WhoAreWe = () => {
  const { t, i18n } = useTranslation();
  const [cardColor1, setCardColor1] = useState({
    bg: "white",
    text: "#6C6C6C",
    title: "black",
  });
  // const [cardColor2, setCardColor2] = useState({
  //   bg: "white",
  //   text: "#6C6C6C",
  //   title: "black",
  // });
  const [cardColor3, setCardColor3] = useState({
    bg: "white",
    text: "#6C6C6C",
    title: "black",
  });
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${WhoAreWeBack})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          borderRadius: 9,
        }}
        className="lg:h-[450px] w-[100%] bg-cover"
        dir={i18n.language === "en" ? "" : "rtl"}
      >
        <div className="lg:grid lg:grid-cols-12 p-5 lg:p-1 space-y-4 lg:space-y-0">
          <div className="hidden lg:block"></div>

          <div className="col-span-4">
            <div
              className="text-[16px] font-['FLight'] mt-4 lg:mt-8"
              style={{ color: theme.orange }}
            >
              {t("WhoAreWe")}
            </div>
            <div className="text-[30px] font-['FBold']">
              {t("WhoAreWeBoldText")}
            </div>
          </div>
          <div></div>
          <div className="col-span-5">
            <div className=" text-[14px] font-['FMed'] lg:mt-8">
              <div>{t("WhoAreWeLightText")}</div>
              {/* {i18n.language === "ar" ? ( */}
              <div className="mt-6 lg:mt-10">{t("WhoAreWeLightText2")}</div>
              {/* ) : (
                ""
              )} */}
            </div>
          </div>

          <div className="hidden lg:block"></div>
        </div>
      </div>
      <div
        className={`lg:grid lg:grid-cols-8 lg:gap-12 lg:mx-[9%] mt-6 ${
          i18n.language == "en" ? " lg:-mt-12" : "lg:-mt-32"
        } `}
        dir={i18n.language === "en" ? "" : "rtl"}
      >
        <div
          className={`col-span-4 my-7 lg:my-0 p-10 ${
            cardColor1.bg === "white" ? "" : "lg:scale-105"
          } space-y-4  shadow-2xl mx-3 lg:mx-0 `}
          style={{
            borderRadius: 5,
            backgroundColor: cardColor1.bg,
            transition: "0.3s",
          }}
          onMouseEnter={() =>
            setCardColor1({
              bg: `${theme.orange}`,
              text: "white",
              title: "white",
            })
          }
          onMouseLeave={() =>
            setCardColor1({ bg: "white", text: "#6C6C6C", title: "black" })
          }
        >
          <div className="flex justify-start items-center">
            <div style={{ backgroundColor: "white" }} className="rounded">
              <div
                className="rounded p-2"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={SearchStatus} alt={":("} />
              </div>
            </div>
            <div
              className="text-[26px] font-['FBold'] mx-4"
              style={{ color: cardColor1.title }}
            >
              {t("Vision")}
            </div>
          </div>
          <div
            className="text-[16px] font-['FLight'] text-[#6C6C6C]"
            style={{ color: cardColor1.text }}
          >
            {t("VisionText")}
          </div>
        </div>
        {/* <div
          className={`col-span-4 p-10 my-7 lg:my-0  ${
            cardColor2.bg === "white" ? "" : "lg:scale-125"
          } space-y-4  shadow-2xl mx-3 lg:mx-0`}
          style={{
            borderRadius: 5,
            backgroundColor: cardColor2.bg,
            transition: "0.3s",
          }}
          onMouseEnter={() =>
            setCardColor2({
              bg: `${theme.orange}`,
              text: "white",
              title: "white",
            })
          }
          onMouseLeave={() =>
            setCardColor2({ bg: "white", text: "#6C6C6C", title: "black" })
          }
        >
          <div className="flex justify-start items-center">
            <div style={{ backgroundColor: "white" }} className="rounded">
              <div
                className="rounded p-2"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={MedalStar} alt={":("} />
              </div>
            </div>
            <div
              className="text-[26px] font-['FBold'] mx-4"
              style={{ color: cardColor2.title }}
            >
              {t("Values")}
            </div>
          </div>
          <div
            className="text-[16px] font-['FLight']"
            style={{ color: cardColor2.text }}
          >
            {t("ValuesText")}
          </div>
        </div> */}
        <div
          className={`col-span-4 p-10 my-7 lg:my-0  ${
            cardColor3.bg === "white" ? "" : "lg:scale-105"
          } space-y-4 shadow-2xl mx-3 lg:mx-0`}
          style={{
            borderRadius: 5,
            backgroundColor: cardColor3.bg,
            transition: "0.3s",
          }}
          onMouseEnter={() =>
            setCardColor3({
              bg: `${theme.orange}`,
              text: "white",
              title: "white",
            })
          }
          onMouseLeave={() =>
            setCardColor3({ bg: "white", text: "#6C6C6C", title: "black" })
          }
        >
          <div className="flex justify-start items-center">
            <div style={{ backgroundColor: "white" }} className="rounded">
              <div
                className="rounded p-2"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={Chart} alt={":("} />
              </div>
            </div>
            <div
              className="text-[26px] font-['FBold'] mx-4"
              style={{ color: cardColor3.title }}
            >
              {t("Goals")}
            </div>
          </div>
          <div
            className="text-[16px] font-['FLight']"
            style={{ color: cardColor3.text }}
          >
            {t("GoalsText")}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoAreWe;
