import React from "react";
import { useTranslation } from "react-i18next";
import ServiceTwoImage from "../../assets/images/servicetwo.png";
import Button from "../Button/Button";
import { theme } from "../../theme";
import ServiceTwoPDF from "../../assets/Pdfs/ServiceTwo1PDF.pdf";
import ServiceTwo2PDF from "../../assets/Pdfs/ServiceTwo2PDF.pdf";
const ServiceTwo = () => {
  const { t, i18n } = useTranslation();
  return (
    <div
      style={{
        backgroundImage: `url(${ServiceTwoImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
      }}
      className="h-[1400px] md:h-[900px] lg:h-[540px] w-full bg-cover lg:grid lg:grid-cols-12 flex flex-col justify-center items-center mt-12"
      dir={i18n.language === "en" ? "" : "rtl"}
    >
      <div className="hidden lg:block"></div>

      <div className="col-span-4 space-y-4 mx-2 lg:mx-0">
        <div className="text-[white] text-[16px] font-['FSLight']">
          {t("OurServices")}
        </div>
        <div className="text-[white] text-[30px] font-['FBold']">
          {t("ServiceTwoTitle")}
        </div>
        <div></div>
        <div className="w-[100%] flex flex-col justify-center items-center lg:items-start">
          <div
            dir="ltr"
            className="text-[16px] p-4 font-['FMed'] text-[#C54A31]"
          >
            {i18n.language == "en"
              ? "Examples of our work:"
              : " :نماذج أعمالنا "}
          </div>
          <Button
            bgColor={theme.orange}
            text={i18n.language == "en" ? "First Model" : "النموذج الأول"}
            textColor={"white"}
            customStyle={"lg:mt-5"}
            onClick={(e) => {
              e.preventDefault();
              window.open(ServiceTwoPDF, "_blank", "noopener,noreferrer");
            }}
          />
          <Button
            bgColor={theme.darkBlue}
            textColor={theme.orange}
            borderColor={theme.ButtonBorder}
            text={i18n.language == "en" ? "Second Model" : "النموذج الثاني"}
            // textColor={"white"}
            customStyle={"lg:mt-5"}
            onClick={(e) => {
              e.preventDefault();
              window.open(ServiceTwo2PDF, "_blank", "noopener,noreferrer");
            }}
          />
        </div>
      </div>
      <div className="hidden lg:block"></div>
      <div className="col-span-5 font-['FSLight'] text-[16px] text-[white] space-y-10 2xl:mt-8 mx-4 lg:mx-0 mt-6 lg:mt-0">
        <div>{t("ServiceTwo1")} </div>
        <div>{t("ServiceTwo2")} </div>
        <div>{t("ServiceTwo3")} </div>
      </div>
      <div></div>
    </div>
  );
};

export default ServiceTwo;
