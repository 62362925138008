import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceTwoImage from "../../assets/images/servicetwo.png";
import refresh from "../../assets/icons/refresh.svg";
import presentation from "../../assets/icons/presention-chart.svg";
import chart from "../../assets/icons/chart-success.svg";
import { theme } from "../../theme/index";

const ServiceSix = () => {
  const { t, i18n } = useTranslation();
  const [cardColor1, setCardColor1] = useState({
    bg: "white",
    text: "#6C6C6C",
    title: "black",
  });
  const [cardColor2, setCardColor2] = useState({
    bg: "white",
    text: "#6C6C6C",
    title: "black",
  });
  const [cardColor3, setCardColor3] = useState({
    bg: "white",
    text: "#6C6C6C",
    title: "black",
  });
  return (
    <div className="flex flex-col justify-center items-center 2xl:mt-8 ">
      <div
        className="flex flex-col justify-start items-center w-full bg-cover h-[800px] md:h-[600px] lg:h-[615px] text-[white] space-y-8 text-center"
        style={{
          backgroundImage: `url(${ServiceTwoImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
        dir={i18n.language === "en" ? "" : "rtl"}
      >
        <div className="font-['FSLight'] text-[16px] mt-8 lg:mt-24">
          {t("OurServices")}
        </div>
        <div className="font-['FBold'] text-[30px] mx-1">
          {t("ServiceSixTitle")}
        </div>
        <div className="font-['FSLight'] text-[16px] lg:w-[72%] py-2 mx-2">
          {t("ServiceSixText1")}
        </div>
        <div className="font-['FSLight'] text-[16px] lg:w-[72%] py-2 pb-5 lg:pb-0 mx-2">
          {t("ServiceSixText2")}
        </div>
      </div>
      <div
        className="lg:grid lg:grid-cols-12 lg:gap-12 lg:mx-[9%] -mt-24 my-4 "
        dir={i18n.language === "en" ? "" : "rtl"}
      >
        <div
          className={`col-span-4 p-10 ${
            cardColor1.bg === "white" ? "" : "lg:scale-110"
          } space-y-4  lg:shadow-2xl mx-3 lg:mx-0`}
          style={{
            borderRadius: 5,
            backgroundColor: cardColor1.bg,
            transition: "0.3s",
          }}
          onMouseEnter={() =>
            setCardColor1({
              bg: `${theme.orange}`,
              text: "white",
              title: "white",
            })
          }
          onMouseLeave={() =>
            setCardColor1({ bg: "white", text: "#6C6C6C", title: "black" })
          }
        >
          <div className="flex justify-center items-center">
            <div style={{ backgroundColor: "white" }} className="rounded">
              <div
                className="rounded p-4"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={chart} alt={":("} />
              </div>
            </div>
          </div>
          <div
            className="text-[18px] font-['FBold'] mx-2 text-center"
            style={{ color: cardColor1.title }}
          >
            {t("ServiceSixHelp")}
          </div>
        </div>
        <div
          className={`col-span-4 p-10 ${
            cardColor2.bg === "white" ? "" : "lg:scale-110"
          } space-y-4  lg:shadow-2xl mx-3 lg:mx-0`}
          style={{
            borderRadius: 5,
            backgroundColor: cardColor2.bg,
            transition: "0.3s",
          }}
          onMouseEnter={() =>
            setCardColor2({
              bg: `${theme.orange}`,
              text: "white",
              title: "white",
            })
          }
          onMouseLeave={() =>
            setCardColor2({ bg: "white", text: "#6C6C6C", title: "black" })
          }
        >
          <div className="flex justify-center items-center">
            <div style={{ backgroundColor: "white" }} className="rounded">
              <div
                className="rounded p-4"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={presentation} alt={":("} />
              </div>
            </div>
          </div>
          <div
            className="text-[18px] font-['FBold'] mx-2 text-center"
            style={{ color: cardColor2.title }}
          >
            {t("ServiceSixImplement")}
          </div>
        </div>
        <div
          className={`col-span-4 p-10 ${
            cardColor3.bg === "white" ? "" : "lg:scale-110"
          } space-y-4  lg:shadow-2xl mx-3 lg:mx-0`}
          style={{
            borderRadius: 5,
            backgroundColor: cardColor3.bg,
            transition: "0.3s",
          }}
          onMouseEnter={() =>
            setCardColor3({
              bg: `${theme.orange}`,
              text: "white",
              title: "white",
            })
          }
          onMouseLeave={() =>
            setCardColor3({ bg: "white", text: "#6C6C6C", title: "black" })
          }
        >
          <div className="flex justify-center items-center">
            <div style={{ backgroundColor: "white" }} className="rounded">
              <div
                className="rounded p-4"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={refresh} alt={":("} />
              </div>
            </div>
          </div>
          <div
            className="text-[18px] font-['FBold'] mx-2 text-center"
            style={{ color: cardColor3.title }}
          >
            {t("ServiceSixFollow")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSix;
