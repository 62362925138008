import React from "react";
import IntroBack from "../../assets/images/introback.png";
import Intro from "../../assets/images/intro.png";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme/index";
import Button from "../Button/Button";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";

const HomeIntro = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="lg:grid lg:grid-cols-12  hidden lg:block">
        <div className="col-span-4 hidden lg:block">
          <div
            className="lg:h-[780px] lg:w-[535px] bg-cover flex flex-col justify-center items-start"
            style={{
              backgroundImage: `url(${IntroBack})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
            }}
          >
            <Fade left>
              <div
                className="lg:h-[435px] lg:w-[550px] xl:w-[660px] rounded-xl bg-cover shadow-2xl"
                style={{
                  backgroundImage: `url(${Intro})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top",
                }}
              ></div>
            </Fade>
          </div>
        </div>
        <div className="hidden lg:block "></div>
        <div className="hidden lg:block 2xl:hidden "></div>
        <div className="hidden lg:block 2xl:hidden"></div>
        <div className="col-span-4 2xl:col-span-6 mx-2 lg:mx-9">
          <div dir={i18n.language === "en" ? "" : "rtl"} className="space-y-4">
            <div className="font-['FBold'] text-[46px] mt-44 lg:mt-28 ">
              {i18n.language == "en"
                ? "Start Your Business with Us "
                : "ابدء شركتك معنا بكل"}
              <span className={`text-[${theme.orange}]`}>
                {i18n.language == "en" ? " & " : " قوة "}
              </span>
              {i18n.language == "en"
                ? "Put Your Company On The "
                : "وخطوات ثابتة"}
              <span className={`text-[${theme.orange}]`}>
                {i18n.language == "en" ? "Steps Of Success" : " نحو النجاح "}
              </span>
            </div>
            <div className="font-['FLight'] text-[14px] text-[#888888] 2xl:pl-20">
              {t("HomeIntroText")}
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-start w-[100%] xl:w-min">
              <div className="w-[100%] flex flex-col justify-center items-center">
                <Button
                  bgColor={theme.orange}
                  text={t("ConactUs")}
                  textColor={"white"}
                  onClick={(e) => {
                    navigate("/aboutus");
                  }}
                />
              </div>
              <div className="w-[100%] flex flex-col justify-center items-center">
                <Link to="S" spy={true} smooth={true}>
                  <Button
                    bgColor={""}
                    text={t("OurServices")}
                    textColor={theme.orange}
                    borderColor={theme.ButtonBorder}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="hidden lg:block"></div>
        </div>
        <div className="hidden lg:block"></div>
      </div>
      {/* for mobile */}
      <div className="lg:hidden">
        <div
          className="h-[1200px] w-full bg-cover flex flex-col justify-center items-center"
          style={{
            backgroundImage: `url(${IntroBack})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
          }}
        >
          <div className="mx-2">
            <div
              dir={i18n.language === "en" ? "" : "rtl"}
              className="space-y-4"
            >
              <div className="font-['FBold'] text-[36px] text-[white] mx-[10%] mt-32 ">
                {i18n.language == "en"
                  ? "Start Your Business with Us"
                  : "ابدء شركتك معنا بكل"}
                <span className={`text-[${theme.orange}]`}>
                  {i18n.language == "en" ? " & " : " قوة "}
                </span>
                {i18n.language == "en"
                  ? "Put Your Company On The"
                  : "وخطوات ثابتة"}
                <span className={`text-[${theme.orange}]`}>
                  {i18n.language == "en" ? " Steps Of Success" : " نحو النجاح "}
                </span>
              </div>
              <div className="font-['FMed'] text-[14px] text-[#888888] mx-[10%]">
                {t("HomeIntroText")}
              </div>
              <div className="flex flex-col lg:flex-row justify-center items-center w-[100%]  ">
                <div className="w-[96%] flex flex-col justify-center items-center ">
                  <Button
                    bgColor={theme.orange}
                    text={t("ConactUs")}
                    textColor={"white"}
                    onClick={(e) => {
                      navigate("/aboutus");
                    }}
                  />
                </div>
                <Link to="S" spy={true} smooth={true} style={{ width: "100%" }}>
                  <div className="w-[100%] flex flex-row justify-center items-center">
                    <Button
                      bgColor={""}
                      text={t("OurServices")}
                      textColor={theme.orange}
                      borderColor={theme.ButtonBorder}
                      customStyle={"w-[100%]"}
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div
            className="h-[540px] w-full rounded mt-8"
            style={{
              backgroundImage: `url(${Intro})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default HomeIntro;
