import React from "react";
import { useTranslation } from "react-i18next";
import ServiceSevenImage from "../../assets/images/serviceseven.png";
import { theme } from "../../theme";
import archovebook from "../../assets/icons/training/archive-book.svg";
import docment from "../../assets/icons/training/document-copy.svg";
import check from "../../assets/icons/training/global-edit.svg";
import graph from "../../assets/icons/training/graph.svg";
import note from "../../assets/icons/training/note-2.svg";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
const ServiceSeven = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="lg:grid lg:grid-cols-12 mmt-8 lg:mt-32">
      <div></div>
      <div
        className="col-span-5 space-y-5 mx-2 lg:mx-0"
        dir={i18n.language === "en" ? "" : "rtl"}
      >
        <div
          className="font-['FSLight'] text-[16px]"
          style={{ color: theme.orange }}
        >
          {t("OurServices")}
        </div>
        <div className="font-['FBold'] text-[30px]">
          {t("ServiceSevenTitle")}
        </div>
        <div
          className="font-['FLight'] text-[16px] "
          style={{ color: "#8E8E8E" }}
        >
          {t("ServiceSevenText")}
        </div>
        <div className="lg:grid grid-cols-2 gap-8 space-y-5 lg:space-y-0">
          <div className="space-y-5 mx-8 lg:mx-0">
            <div
              className="flex flex-col justify-center items-start p-4 rounded space-y-4"
              style={{ backgroundColor: "#EBF0F1" }}
            >
              <div
                className=" rounded p-2"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={note} alt={":("} />
              </div>
              <div className="font-['FMed'] text-[16px]">
                {t("ServiceSeven1")}
              </div>
            </div>

            <div
              className="flex flex-col justify-center items-start p-4 rounded space-y-4"
              style={{ backgroundColor: "#EBF0F1" }}
            >
              <div
                className=" rounded p-2"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={archovebook} alt={":("} />
              </div>
              <div className="font-['FMed'] text-[16px]">
                {t("ServiceSeven5")}
              </div>
            </div>
            <div
              className="flex flex-col justify-center items-start p-4 rounded space-y-4"
              style={{ backgroundColor: "#EBF0F1" }}
            >
              <div
                className=" rounded p-2"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={docment} alt={":("} />
              </div>
              <div className="font-['FMed'] text-[16px]">
                {t("ServiceSeven3")}
              </div>
            </div>
          </div>

          <div className="space-y-5  mx-8 lg:mx-0">
            <div
              className="flex flex-col justify-center items-start p-4 rounded space-y-4"
              style={{ backgroundColor: "#EBF0F1" }}
            >
              <div
                className=" rounded p-2"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={graph} alt={":("} />
              </div>
              <div className="font-['FMed'] text-[16px]">
                {t("ServiceSeven2")}
              </div>
            </div>
            <div
              className="flex flex-col justify-center items-start p-4 rounded space-y-4"
              style={{ backgroundColor: "#EBF0F1" }}
            >
              <div
                className=" rounded p-2"
                style={{ backgroundColor: theme.lightOrange }}
              >
                <img src={check} alt={":("} />
              </div>
              <div className="font-['FMed'] text-[16px]">
                {t("ServiceSeven4")}
              </div>
            </div>
            <div
              className="flex flex-col justify-center items-start p-4 rounded space-y-4"
              style={{ backgroundColor: theme.orange }}
            >
              <div
                className="text-[18px] font-['FLight'] text-center self-center"
                style={{ color: "white" }}
              >
                {t("ServiceFourStart")}
              </div>
              <div className="w-[100%] flex flex-col justify-center items-center">
                <Button
                  bgColor={"white"}
                  text={t("ConactUs")}
                  textColor={theme.orange}
                  onClick={(e) => {
                    navigate("/aboutus");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block"></div>
      <div className="hidden 2xl:block"></div>
      <div className="hidden 2xl:block"></div>
      <div
        className="col-span-5 2xl:col-span-3 my-4 lg:my-0 bg-cover lg:bg-contain h-[500px] lg:h-[780px] col-span-5"
        style={{
          backgroundImage: `url(${ServiceSevenImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      ></div>
    </div>
  );
};

export default ServiceSeven;
